import React from "react";
import ContactForm from './ContactForm';

const ContactPage = () => {
	return (
    <>
      <section className="inventory-container">
        <div className="inventory-title">Contact Us</div>
      </section>

      <section className="car-contact">
        <div className="car-contact-container">
          <div className="car-contact-information">
            <div className="car-dealership">
              <div className="car-dealership-title dark">Serene Auto</div>
              <div className="car-dealership-info dark">961 Quest Blvd, Ile des Chênes, MB R0A 0T0</div>
              <div className="car-dealership-info dark">+1 (431) 588-4511</div>
              <div className="car-dealership-info dark">Info@sereneauto.ca</div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2579.734213567696!2d-96.99777890000001!3d49.7158053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52c1d0a885a3bec3%3A0xff2d8d9d06082ff!2s961%20Quest%20Blvd%2C%20Ile%20des%20Ch%C3%AAnes%2C%20MB%20R0A%200T0%2C%20Canada!5e0!3m2!1sen!2sro!4v1729848732664!5m2!1sen!2sro" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <ContactForm/>
        </div>
      </section>
    </>
	);
}
  
  export default ContactPage;