import Button from 'react-bootstrap/Button';
import Preview from './Preview';
import ContactForm from './ContactForm';

import { db } from "./firebase";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//ALL LOGOS FOR THE 'our brand' section
import { FaRegHandshake } from "react-icons/fa";
import { GiHomeGarage } from "react-icons/gi";
import { LiaUserClockSolid } from "react-icons/lia";

//ALL LOGOS FOR THE 'our brand' section
import { SiHonda } from "react-icons/si";
import { SiToyota } from "react-icons/si";
import { SiMercedes } from "react-icons/si";
import { SiBmw } from "react-icons/si";
import { SiTesla } from "react-icons/si";
import { SiFord } from "react-icons/si";
import { SiMitsubishi } from "react-icons/si";
import { SiHyundai } from "react-icons/si";
import { SiNissan } from "react-icons/si";
import { SiKia } from "react-icons/si";

const HomePage = () => {
  const [defaultLoad, setDefaultLoad] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');

  const [inventory, setInventory] = useState([]);
  const [makeFilter, setMakeFilter] = useState('');
  const [modelFilter, setModelFilter] = useState('');
  const [maxPriceFilter, setMaxPriceFilter] = useState('');
  const [minYearFilter, setMinYearFilter] = useState('');
  const [maxKilometersFilter, setMaxKilometersFilter] = useState('');
  const [filteredInventory, setFilteredInventory] = useState([]);

  useEffect(() => {
    const unsubscribe = db.collection('inventory').orderBy('status', 'asc').limit(defaultLoad).onSnapshot(snapshot => {
      const inventory = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setMakeFilter('');
      setModelFilter('');
      setMaxKilometersFilter('');
      setMaxPriceFilter('');
      setMinYearFilter('');
      // setSortOrder('status-desc');
      setFilteredInventory(inventory);
      setInventory(inventory);
      setLoading(false);
    });

    setFilteredInventory([]);

    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    document.body.scrollTop = 0; // For Safari

    return unsubscribe;
  }, [defaultLoad]);

  useEffect(() => {
    handleFilter();
  }, [inventory && inventory.length > 0]);

  useEffect(() => {
    handleFilter();
  }, [makeFilter, modelFilter, maxPriceFilter, minYearFilter, maxKilometersFilter]);

  useEffect(() => {
    setModelFilter('');
    setMaxKilometersFilter('');
    setMaxPriceFilter('');
    setMinYearFilter('');
  }, [makeFilter]);

  const handleFilter = () => {
    const filteredInventory = inventory.filter((vehicle) =>
      vehicle.make.toLowerCase().includes(makeFilter.toLowerCase()) &&
      vehicle.model.toLowerCase().includes(modelFilter.toLowerCase()) &&
      (maxPriceFilter === '' || parseFloat(vehicle.askingPrice) <= parseFloat(maxPriceFilter)) &&
      (minYearFilter === '' || parseFloat(vehicle.year) >= parseInt(minYearFilter, 10)) &&
      (maxKilometersFilter === '' || parseFloat(vehicle.vehicleKilometers) <= parseFloat(maxKilometersFilter))
    );

    setFilteredInventory(filteredInventory);
  };

  return (
    <div className="page-content">
      <section className="car-find">
        <div className="car-find-description">
          <div className="car-sm-description pink">Modern car design</div>
          <div className="car-lg-description dark">Find Your Dream Car</div>
          <div className="car-paragraph dark">Discover the ultimate automotive experience with Serene Auto. From luxury vehicles to exceptional customer service, we strive to provide a serene and stress-free car buying experience. Browse our extensive inventory and let us help you find your perfect ride. Visit us today!</div>
          <Button className="custom-button show-result-fix"><Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: '', availabilityFilter: '' }))}>Browse Inventory</Link></Button>

          <div className="car-search-container">
            <div className="form-floating">
              <select aria-label="Floating label select example" className="form-select" value={makeFilter} onChange={e => setMakeFilter(e.target.value)}>

                <option value="">All Makes</option>
                {Object.keys(inventory.reduce((acc, vehicle) => ({ ...acc, [vehicle.make.toUpperCase()]: (acc[vehicle.make] || 0) + 1 }), {}))
                  .sort()
                  .map((make, index) => (
                    <option value={make} key={index}>{`${make} (${inventory.reduce((acc, vehicle) => (vehicle.make.toUpperCase() === make.toUpperCase() ? acc + 1 : acc), 0)})`}</option>
                  ))}

              </select>
              <label>Make</label>
            </div>

            <div className="form-floating" >
              <select disabled={makeFilter !== "" ? false : true} aria-label="Floating label select example" className="form-select" value={modelFilter} onChange={e => setModelFilter(e.target.value)}>
                <option value="">All Models</option>
                {Object.keys(
                  inventory.reduce((acc, vehicle) => ({ ...acc, [vehicle.model]: (acc[vehicle.model] || 0) + 1 }), {})
                ).map((model, index) => {
                  const count = inventory.reduce((acc, vehicle) => (vehicle.model === model && vehicle.make.toUpperCase() == makeFilter ? acc + 1 : acc), 0)
                  return count > 0 ? <option value={model} key={index} > {`${model} (${count})`}</option> : <></>
                }
                )}
              </select>
              <label>Model</label>
            </div>

            <div className="form-floating">
              <input type="text" className="form-control" value={maxPriceFilter} onChange={(e) => setMaxPriceFilter(e.target.value)} />
              <label>Max Price ($)</label>
            </div>

            <div className="form-floating year-select">
              <select aria-label="Floating label select example" className="form-select" value={minYearFilter} onChange={e => setMinYearFilter(e.target.value)}>
                <option value="">All Years</option>
                {/* {(makeFilter.length > 0|| modelFilter.length > 0 || maxPriceFilter.length > 0|| maxKilometersFilter.length > 0) ?
                <>
                  {filteredInventory.sort((a, b) => a.year - b.year).map((vehicle, index) => (
                    <option value={vehicle.year} key={index}>{vehicle.year}</option>
                  ))}
                </> : 
                <>
                  {inventory.sort((a, b) => a.year - b.year).map((vehicle, index) => (
                    <option value={vehicle.year} key={index}>{vehicle.year}</option>
                  ))}
                </>
                } */}

                {Object.keys(
                  filteredInventory.reduce((acc, vehicle) => ({ ...acc, [vehicle.year]: (acc[vehicle.year] || 0) + 1 }), {})
                ).map((year, index) => (
                  <option value={year} key={index}>{`${year}`}</option>
                ))}
              </select>
              <label>Min Year</label>
            </div>

            <div className="form-floating">
              <input type="text" className="form-control" value={maxKilometersFilter} onChange={(e) => setMaxKilometersFilter(e.target.value)} />
              <label>Max Kilometers</label>
            </div>

            <Button className="custom-reset-button show-result-fix"><Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter, modelFilter, maxKilometersFilter, maxPriceFilter, minYearFilter, carType: '', availabilityFilter: '' }))}>Show Results ({filteredInventory.length})</Link></Button>
          </div>

          <div className="car-category-container">
            <Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: 'Sedan', availabilityFilter: '' }))}>
              <div className="car-category">
                <img src="https://www.kijijiautos.ca/static/asset-catalog/icons/icon-bodytype-sedan.svg" />
                <div className="car-category-type">Sedan</div>
              </div>
            </Link>


            <Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: 'SUV', availabilityFilter: '' }))}>
              <div className="car-category">
                <img src="https://www.kijijiautos.ca/static/asset-catalog/icons/icon-bodytype-suv-crossover.svg" />
                <div className="car-category-type">SUV</div>
              </div>
            </Link>

            <Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: 'Truck', availabilityFilter: '' }))}>
              <div className="car-category">
                <img src="https://www.kijijiautos.ca/static/asset-catalog/icons/icon-bodytype-pickup.svg" />
                <div className="car-category-type">Pickup truck</div>
              </div>
            </Link>

            <Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: 'Hatchback', availabilityFilter: '' }))}>
              <div className="car-category">
                <img src="https://www.kijijiautos.ca/static/asset-catalog/icons/icon-bodytype-hatchback.svg" />
                <div className="car-category-type">Hatchback</div>
              </div>
            </Link>


            <Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: 'Van', availabilityFilter: '' }))}>
              <div className="car-category">
                <img src="https://www.kijijiautos.ca/static/asset-catalog/icons/icon-bodytype-van.svg" />
                <div className="car-category-type">Van</div>
              </div>
            </Link>

            <Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: 'Other', availabilityFilter: '' }))}>
              <div className="car-category">
                <img src="https://www.kijijiautos.ca/static/asset-catalog/icons/icon-bodytype-sedan.svg" />
                <div className="car-category-type">Other</div>
              </div>
            </Link>

            {/* <div className="car-category">
              <img src="https://www.kijijiautos.ca/static/asset-catalog/icons/icon-bodytype-wagon.svg"/>
              <div className="car-category-type">Wagon</div>
            </div> */}

            {/* <div className="car-category">
              <img src="https://www.kijijiautos.ca/static/asset-catalog/icons/icon-bodytype-convertible.svg"/>
              <div className="car-category-type">Convertible</div>
            </div> */}

          </div>
        </div>
      </section>

      <section className="car-help padding-fix">
        <div className="car-help-container">
          <div className="car-help-description">
            <div className="car-sm-description pink">OUR SERVICES</div>
            <div className="car-md-description light"><span className="car-highlight">WHY </span> CHOOSE US?</div>
            <div className="car-paragraph gray">Find the perfect used car for your needs at our dealership. Explore our extensive inventory of high-quality used vehicles and make a smart choice for your next car purchase. Trust us to provide reliable options and exceptional customer service throughout the buying process.</div>
          </div>
        </div>

        <div className="car-help-steps">
          <div className="car-step">
            <div className="car-step-top">
              <div className="car-step-logo"><FaRegHandshake size={35} color="#e11d4b" /></div>
              <div className="car-step-number light">01</div>
            </div>
            <div className='help-desc light'>Trusted By Thousands</div>
            <div className='help-para gray'>As a respected industry frontrunner, we are deeply devoted to delivering top-notch quality and exceptional service. Embark on a journey of automotive excellence with Serene Auto.</div>
          </div>

          <div className="car-step">
            <div className="car-step-top">
              <div className="car-step-logo"><GiHomeGarage size={35} color="#e11d4b" /></div>
              <div className="car-step-number light">02</div>
            </div>
            <div className='help-desc light'> Wide Range Of Vehicles</div>
            <div className='help-para gray'>Choose from our versatile range of vehicles, each designed to suit various preferences and lifestyles. Whether you seek elegance, power, or adventure, we have the perfect ride for you.</div>
          </div>

          <div className="car-step">
            <div className="car-step-top">
              <div className="car-step-logo"><LiaUserClockSolid size={35} color="#e11d4b" /></div>
              <div className="car-step-number light">03</div>
            </div>
            <div className='help-desc light'> Faster Buy & Sell</div>
            <div className='help-para gray'>Our efficient Buy & Sell service ensures quick and hassle-free transactions for all your automotive needs. Whether buying or selling, we prioritize speed and simplicity to get you on the road or find the perfect buyer in no time.</div>
          </div>
        </div>
      </section>

      <section className="car-featured-container padding-fix">
        <div className="car-featured-title-container" >
          <div className="car-featured-title">
            <div className="car-sm-description pink">Special Offers</div>
            <div className="car-md-description dark"><span className="car-highlight">Featured</span> Cars</div>
          </div>
          <Button className="custom-button show-result-fix"><Link to={`/inventory`} onClick={() => localStorage.setItem("inventoryState", JSON.stringify({ makeFilter: '', modelFilter: '', maxKilometersFilter: '', maxPriceFilter: '', minYearFilter: '', carType: '', availabilityFilter: '' }))}>All Listings</Link></Button>
        </div>
        <Preview />
      </section>

      <section className="car-help padding-fix">
        <div className="car-help-container">
          <div className="car-help-description">
            <div className="car-sm-description pink">WIDE SELECTION</div>
            <div className="car-md-description light"><span className="car-highlight">OUR</span> BRANDS</div>
            <div className="car-paragraph gray">At Serene Auto, we curate a diverse selection of car brands, providing unparalleled
              access to automotive excellence under one virtual roof. Explore our extensive lineup, each brand offering a unique blend
              of innovation and style, inviting you to discover their distinctive stories.</div>
          </div>

          <div className="car-brands-container">
            <div className="car-brand"><SiHonda size={50} color="#171717" /></div>
            <div className="car-brand"><SiToyota size={50} color="#171717" /></div>
            <div className="car-brand"><SiMercedes size={50} color="#171717" /></div>
            <div className="car-brand"><SiBmw size={50} color="#171717" /></div>
            <div className="car-brand"><SiTesla size={50} color="#171717" /></div>
          </div>
          <div className="car-brands-container">
            <div className="car-brand"><SiFord size={50} color="#171717" /></div>
            <div className="car-brand"><SiMitsubishi size={50} color="#171717" /></div>
            <div className="car-brand"><SiHyundai size={50} color="#171717" /></div>
            <div className="car-brand"><SiNissan size={50} color="#171717" /></div>
            <div className="car-brand"><SiKia size={50} color="#171717" /></div>
          </div>
        </div>
      </section>

      <section className="car-contact padding-fix">
        <div className="car-help-container">
          <div className="car-help-description">
            <div className="car-sm-description pink">WE'RE HERE TO HELP</div>
            <div className="car-md-description dark"><span className="car-highlight">CONTACT</span> US</div>
          </div>
        </div>

        <div className="car-contact-container">
          <div className="car-contact-information">
            <div className="car-dealership">
              <div className="car-dealership-title dark">Serene Auto</div>
              <div className="car-dealership-info dark">961 Quest Blvd, Ile des Chênes, MB R0A 0T0</div>
              <div className="car-dealership-info dark">+1 (431) 588-4511</div>
              <div className="car-dealership-info dark">Info@sereneauto.ca</div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2579.734213567696!2d-96.99777890000001!3d49.7158053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52c1d0a885a3bec3%3A0xff2d8d9d06082ff!2s961%20Quest%20Blvd%2C%20Ile%20des%20Ch%C3%AAnes%2C%20MB%20R0A%200T0%2C%20Canada!5e0!3m2!1sen!2sro!4v1729848732664!5m2!1sen!2sro" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <ContactForm />
        </div>
      </section>
    </div>
  );
}
export default HomePage;