import _, { unset } from "lodash";
import { Button } from "react-bootstrap";

const map = {

    '@Equipment.POWER LOCKS': ['@Safety.Power Door Locks'],
    '@Equipment.POWER STEERING': ['@Steering.Power Steering'],
    '@Equipment.ABS': ['@Braking.ABS'],
    '@Equipment.POWER WINDOWS': ['@Feature.Power Windows'],
    '@Equipment.AIR CONDITIONING': ['@Comfort.Air Conditioning'],
    '@Equipment.TRACTION CONTROL': ['@Drivetrain.Traction Control'],
    '@Equipment.CRUISE CONTROL': ['@Feature.Cruise Control'],
    '@Equipment.POWER MIRRORS': ['@Feature.Power Adjustable Exterior Mirror'],
    '@Equipment.BACK-UP CAMERA': ['@Safety.Backup Camera'],
    '@Equipment.AM/FM': ['@Entertainment.AM/FM Radio'],
    '@Equipment.FOG LIGHTS': ['@Lighting.Fog Lights'],
    '@Equipment.4WD': ['@Drivetrain.Driveline'],
    '@Equipment.POWER DRIVER SEAT': ['@Seating.Driver Multi-Adjustable Power Seat'],
    '@Equipment.SPOILER': ['@Feature.Rear Spoiler'],
    '@Equipment.REMOTE START': ['@Safety.Remote Ignition'],
    '@Equipment.AM/FM/CD': ['@Entertainment.AM/FM Radio', '@Entertainment.CD Player'],
    '@Equipment.SEAT - POWER DRIVER AND PASSENGER': ['@Seating.Driver Multi-Adjustable Power Seat', '@Seating.Passenger Multi-Adjustable Power Seat'],
    '@Equipment.SEAT COVERING TYPE LEATHER': ['@Seating.Leather Seat'],
    '@Equipment.7 PASSENGER': ['@Seating.7-Passenger Seating'],
    '@Equipment.KEYLESS ENTRY': ['@Safety.Keyless Entry'],
    '@Equipment.COOLED SEATS FRONT ONLY': ['@Seating.Front Cooled Seat', '@Seating.Cooled Seats'],
    '@Equipment.PEDALS - ADJUSTABLE': ['@Feature.Adjustable Foot Pedals'],
    '@Equipment.POWER PASSENGER SEAT': ['@Seating.Passenger Multi-Adjustable Power Seat'],
    '@Equipment.8 PASSENGER': ['@Seating.8-Passenger Seating'],
    '@Equipment.INDEPENDENT CLIMATE CONTROL': ['@Comfort.Separate Driver/Front Passenger Climate Controls'],
    '@Equipment.ADAPTIVE CRUISE CONTROL': ['@Comfort.Adaptive Cruise Control', '@Comfort.Dynamic Cruise Control'],
}

const allDriveLines = { FWD: "FWD", RWD: "RWD", AWD: "AWD", '4x4': "4WD", '4x2': "2WD" }
const transmissions = { 'Automatic': 'Automatic Transmission', 'Manual': 'Manual Transmission', 'Automated-Manual': 'Automated Manual Transmission', 'Continuously-Variable': 'Continuously Variable Transmission', }

export default function AdTemplate({ features = {}, detail }) {
    const nullProps = ['make', 'model', 'year', 'mileage', 'askingPrice', 'trim', 'driveType', 'engineType', 'transmission'].filter(prop => !detail[prop]);

    if (nullProps.length > 0)
        return <p className="bold">Please enter value for folowing fields: {nullProps.join(' , ')}</p>
    else if (_.isEmpty(features) || _.isEmpty(features.adFeatures))
        return <p className="bold">No Feature Selected!</p>


    const uniqEquip = new Set();
    const blackList = new Set();


    for (let i = 0; i < features.adFeatures.length; i++) {
        let val = features.adFeatures[i];

        let path;
        if (val.startsWith('@Equipment')) {

            if (/POWER.*LOCKS/.test(val))
                path = '@Equipment.POWER LOCKS'
            else if (/(POWER|ELECTRIC).*WINDOWS/.test(val))
                path = '@Equipment.POWER WINDOWS'
            else if (/(POWER|ELECTRIC).*MIRRORS/.test(val))
                path = '@Equipment.POWER MIRRORS'
            else if (map[val])
                path = val

            if (path)
                blackList.add(...map[path]);
        }


        // uniqEquip.add(getTagValue(val, features));

    }
    features.adFeatures.forEach(prop => !blackList.has(prop) && uniqEquip.add(getTagValue(prop, features)));

    let { year = '', make = '', model = '', mileage = '', askingPrice = '', trim = '', driveType = '', engineType = '', transmission = '' } = detail;
    driveType = allDriveLines[driveType];

    let name = `${year} ${make} ${model} ${trim} `;
    if (!/[4 2 F R A]+WD/.test(trim) && driveType != 'FWD')
        name += driveType + ' ';

    if (transmission)
        name += ', ' + transmissions[transmission];
    if (engineType)
        name += ', ' + engineType + ' engine';


    let nf = new Intl.NumberFormat('en-US');

    const highValue = `${features['High Value'] && Object.keys(features['High Value']).length > 0 ? 'Premium Features:' : ''}

${features['High Value'] ? Object.keys(features['High Value']).join('\n') : ''}`;

    const template = `
${year} ${make} ${model} ${trim}, Clean Title, Fresh Safety

Your Perfect Ride Awaits at Serene Auto with Free 3-Month Powertrain Warranty on All Vehicles!

For complete details and Carfax Report, feel free to visit our website:

🌐 www.sereneauto.ca

Vehicle Overview:
Model: ${year} ${make} ${model} ${trim}
Condition: Clean Title, Fresh Safety
${transmission ? 'Transmission: ' + transmissions[transmission] : ''}
${engineType ? 'Engine: ' + engineType : ''}
Mileage: ${nf.format(mileage)} km

${highValue.trim()}

${uniqEquip.size > 0 ? 'Features:' : ''}

${Array.from(uniqEquip).filter(val => val).join('\n')}

And more...

Asking: $${nf.format(askingPrice)} + Taxes
Dealer # 5777

Why Choose Serene Auto:

- Free 3-Month Powertrain Warranty on All Vehicles
- 2-Week Exchange Policy
- Financing Available
- Trade-Ins Welcome
- Extended Warranty Options

📞 Call to Book Your Appointment: 431-588-4511

📍 Location: 961 Quest Blvd, Ile des Chênes, MB R0A 0T0

Before you come in, we kindly request that you book an appointment. By booking a time, you help us serve you better and make your car shopping experience smooth and enjoyable. Whether you're interested in a test drive or simply want to explore our inventory, we're here to accommodate your needs.`
    // uniqEquip.values().join('\n');
    const file = new Blob([template], { type: 'text/plain' });

    return (
        <div className="d-flex flex-column">
            <div className='d-flex flex-row ms-auto'>
                <Button variant="secondary" className="mx-1" size="sm">
                    <a download={name + ".txt"} target="_blank" rel="noreferrer" href={URL.createObjectURL(file)} style={{ textDecoration: 'none', color: 'inherit' }}>Download</a>
                </Button>
                <Button variant="secondary" className="mx-1" size="sm" onClick={() => navigator.clipboard.writeText(template)}>Copy</Button>
            </div>
            <pre>{template}</pre>
        </div>
    );
}


export function getTagValue(tag, features) {
    if (tag && tag.startsWith('@') && tag.includes('.')) {
        tag = tag.replace('@', '');

        const label = tag.split('.')[1];
        const feature = _.get(features, tag, {});
        tag = label;

        if (['no', 'n/a'].includes(
            typeof feature.selected == 'string' ? feature.selected.toLowerCase() : ''
        ))
            return '';

        if (feature.selected != 'Yes')
            tag += `: ${feature.selected}`;
    }
    return tag;
}

